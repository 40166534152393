import React from "react";

import cookie from "../../icons/cookie.png";
import Button from "../../DesignSystem/Button";

import * as S from "./style";

export default function Cookies(props) {
  return (
    <S.Cookies
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <S.Popup
        initial={{ opacity: 0, scale: 0.75, y: 0 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        exit={{ opacity: 0, scale: 0.75, y: 200 }}
      >
        <img src={cookie} alt="cookie" />
        <p>
          Nós usamos cookies em nosso site para analisar o tráfego e melhorar
          sua experiência{" "}
          <a href="/" target="_blank" rel="noopener noreferrer">
            Política de Privacidade
          </a>
        </p>
        <div className="buttons">
          <Button
            onClick={props.rejectCookies}
            size="small"
            bg="gray"
            color="text"
          >
            Rejeitar
          </Button>
          <Button
            onClick={props.acceptCookies}
            size="small"
            bg="laranja"
            color="branco"
          >
            Aceitar
          </Button>
        </div>
      </S.Popup>
    </S.Cookies>
  );
}
