import styled, { css } from "styled-components";
// import { Container } from "../../styles/UI";
// import { Input } from "../Input/style";

export const Banner = styled.div`
  background: linear-gradient(90deg, #000001 0%, #0a3794 100%);
  color: white;
  padding: 8px;

  img {
    height: 64px;
    margin-right: 32px;
  }

  .bannerFlex {
    text-align: center;

    p {
      font-size: 24px;
      font-weight: 600;
    }

    .tempo {
      display: flex;
      margin: 16px auto;
      align-items: center;
      justify-content: center;
      user-select: none;

      .bloco {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 8px;

        p.grande {
          font-size: 24px;
          font-weight: 600;
        }

        p.legenda {
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    position: sticky;
    top: 0;
    z-index: 99999;
    padding: 2px;

    .bannerFlex {
      display: flex;
      align-items: center;
    }
  }
`;

export const Navbar = styled.nav`
  color: var(--color-primary);
  padding: 32px 0;
  background: ${(p) => p.theme.degradeAzul};

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    position: relative;
    background: none;

    .hamburguer-menu {
      display: none;
    }
  }
`;

export const MenuButton = styled.button`
  margin-right: 0;
  margin-left: auto;
  height: 48px;
  width: 48px;
  background-color: ${(p) => (p.collapsed ? p.theme.laranja : "transparent")};
  border-radius: 0px 12px;
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  box-sizing: border-box;
  z-index: 1101;

  span {
    display: block;
    background-color: var(--color-white);
    width: 100%;
    height: 4px;
    border-radius: 2px;
    transition: all 200ms ease-in-out;
    margin: 0 auto;
    opacity: 1;
  }

  div {
    display: block;
    width: 100%;
    height: 8px;

    span:first-child {
      transform: translateY(50%) rotate(0deg);
    }
    span:last-child {
      transform: translateY(-50%) rotate(0deg);
    }
  }

  ${(p) =>
    !p.collapsed &&
    css`
      position: fixed;
      right: 22px;

      span:first-child {
        width: 0%;
        opacity: 0;
        transform: rotate(45deg);
      }
      span:last-child {
        width: 0%;
        opacity: 0;
        transform: rotate(-45deg);
      }

      div {
        span:first-child {
          transform: translateY(50%) rotate(45deg);
          width: 100%;
          opacity: 1;
        }
        span:last-child {
          transform: translateY(-50%) rotate(-45deg);
          width: 100%;
          opacity: 1;
        }
      }
    `}

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    display: none;
  }
`;

export const Navigation = styled.div`
  z-index: 1100;

  @media (max-width: ${(p) => p.theme.bp.tablet}) {
    .only-desktop {
      display: none !important;
    }

    display: ${(p) => (p.collapsed ? "block" : "block")};
    height: ${(p) => (p.collapsed ? "0px" : "100%")};
    padding-top: ${(p) => (p.collapsed ? "0px" : "125px")};
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
    overflow: hidden;
    transition: all 250ms ease-in-out;
    background: rgba(0, 0, 0, 0.85)
      linear-gradient(
        180deg,
        rgba(253, 110, 75, 0.9) 0%,
        rgba(0, 70, 215, 0.9) 100%
      );

    @supports (backdrop-filter: none) {
      background: linear-gradient(
        180deg,
        rgba(253, 110, 75, 0.9) 0%,
        rgba(0, 70, 215, 0.9) 100%
      );
      backdrop-filter: blur(4px);
    }

    text-align: center;

    a {
      font-style: normal;
      font-size: 30px;
      line-height: 76px;
      text-align: center;
      color: var(--color-white);
      border-bottom: 1px solid #fd6e4b50;

      &:last-child {
        border-bottom: none;
      }
    }

    .produtos a {
      display: block;
      font-style: normal;
      font-size: 30px;
      line-height: 76px;
      text-align: center;
      color: var(--color-white);
      border-bottom: 1px solid #fd6e4b50;
    }

    a.active {
      font-weight: 600;
    }

    & div:last-child {
      display: none;
    }
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    display: flex;
    align-items: center;
  }
`;

export const Links = styled.div`
  margin-right: 32px;
  margin-left: auto;

  .only-mobile {
    display: none;

    @media (max-width: ${(p) => p.theme.bp.tablet}) {
      display: block;
    }
  }

  @media (max-width: ${(p) => p.theme.bp.tablet}) {
    display: flex;
    flex-direction: column;
  }

  a {
    text-decoration: none;
    color: var(--color-white);
    margin-left: 32px;
    transition: all 200ms ease-in-out;
    position: relative;
  }

  .produtos {
    display: inline-block;
  }

  .produtos-menu {
    display: none;
  }

  button.teste {
    font-size: 16px;
    font-style: normal;
    color: var(--color-white);
    background: none;
    border: none;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    @media (max-width: ${(p) => p.theme.bp.tablet}) {
      font-size: 30px;
      line-height: 76px;
      text-align: center;
      width: 100%;
    }
  }

  button.demonstracao {
    margin-left: 24px;
    padding: 10px 16px;
    display: inline-flex;

    &:hover {
      color: ${(p) => p.theme.primary};
    }
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    .produtos:hover .produtos-menu {
      opacity: 1;
      height: 72px;
      padding: 8px 16px;
    }

    .produtos-menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      background: white;
      margin-left: 12px;
      margin-top: 8px;
      box-sizing: border-box;
      padding: 0 16px;
      border-radius: 0 12px;
      opacity: 0;
      height: 0;
      transition: all 200ms ease-in-out;
      box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px 0px,
        rgba(9, 30, 66, 0.25) 0px 20px 32px -8px;

      a {
        color: ${(p) => p.theme.primary};
        margin-left: 0;
        padding: 4px 4px;
      }
    }

    a.active {
      color: ${(p) => p.theme.secondary};
      font-weight: 600;
    }

    a:hover {
      color: ${(p) => p.theme.secondary};
    }

    button.teste:hover {
      color: ${(p) => p.theme.secondary};
    }
  }
`;

export const Login = styled.a`
  display: none;

  @media (min-width: ${(p) => p.theme.bp.smallDesktop}) {
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.branco};
    margin-left: 24px;
    transition: all 150ms ease-in-out;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: ${(p) => p.theme.secondary};
    }

    svg {
      margin-left: 8px;
    }
  }
`;
