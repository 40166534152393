import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import { motion, AnimatePresence } from "framer-motion";

import Portal from "./portal";
import * as S from "./style";

import CloseIcon from "../../icons/close.inline.svg";

const Modal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false);

  const isBrowser = typeof window !== "undefined";

  useEffect(() => {
    const close = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        handleClose();
      }
    };
    if (isBrowser) window.addEventListener("keydown", close);
    return () => {
      if (isBrowser) window.removeEventListener("keydown", close);
    };
  }, [isBrowser]);

  useEffect(() => {
    if (display) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    // props.onDisplayChange && props.onDisplayChange(display);
  }, [display]);

  useImperativeHandle(ref, () => {
    return {
      openModal: () => handleOpen(),
      closeModal: () => setDisplay(false),
    };
  });

  const handleOpen = () => {
    setDisplay(true);
  };

  const handleClose = () => {
    if (props.cantClose) return;
    setDisplay(false);
  };
  return (
    <AnimatePresence>
      {display && (
        <Portal>
          <S.Modal
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            small={props.small}
          >
            <div className="modal-wrapper">
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                className="background"
                onClick={handleClose}
                onKeyPress={(e) => {
                  if ((e.charCode || e.keyCode) === 27) {
                    handleClose();
                  }
                }}
              ></div>
              <motion.div
                initial={{ opacity: 0, scale: 0.5, y: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.25, y: 400 }}
                className="modal"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal_label"
                onClick={(e) => e.stopPropagation()}
              >
                {!props.cantClose && <button className="close" onClick={handleClose}>
                  <CloseIcon />
                </button>}
                <div className="modal-header"></div>
                <div className="modal-body">{props.children}</div>
              </motion.div>
            </div>
          </S.Modal>
        </Portal>
      )}
    </AnimatePresence>
  );
});

export default Modal;
