import styled, { css } from "styled-components";
import { Footer as FooterWrapper } from "../../components/Footer/style.js";
import { Navbar as NavbarWrapper } from "../../components/Navbar/style.js";

export const Input = styled.div`
  border-radius: 0 16px 0 16px;
  display: flex;
  align-items: center;
  border: 2px solid ${(p) => p.theme[p.$color]};
  padding: 2px;
  box-sizing: border-box;
  margin-top: ${(p) => (p.$mt ? p.$mt : "0")}px;
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")}px;
  margin-left: ${(p) => (p.$center ? "auto" : 0)};
  margin-right: ${(p) => (p.$center ? "auto" : 0)};

  ${NavbarWrapper} & {
    @media (max-width: ${(p) => p.theme.bp.tablet}) {
      display: none;
    }
    @media (max-width: ${(p) => p.theme.bp.tablet}) {
      display: flex;
    }
  }

  ${NavbarWrapper} &, ${FooterWrapper} & {
    border: 2px solid var(--color-white);
    background-color: var(--color-white);

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      width: 360px;
    }
  }

  ${(p) =>
    p.$negative &&
    css`
      border: 2px solid var(--color-white);
      background-color: var(--color-white);
    `}

  input {
    flex-grow: 1;
    align-self: stretch;
    border: none;
    border-radius: 0 16px 0 16px;
    outline: none;
    font-size: 16px;
    padding-left: 16px;
    box-sizing: border-box;
    background: transparent;

    @media (max-width: ${(p) => p.theme.bp.mobile}) {
      flex-shrink: 1;
      width: 100%;
    }
  }

  ${(p) =>
    p.$big &&
    css`
      @media (max-width: ${(p) => p.theme.bp.tablet}) {
        flex-direction: column;
        align-items: stretch;
        border: 2px solid transparent;

        input {
          flex-direction: column;
          align-items: stretch;
          border-radius: 0 16px 0 16px;
          border: 2px solid ${p.theme[p.$color]};
          margin-bottom: 8px;
          height: 64px;
          padding-right: 16px;
        }

        &&& button {
          width: 100%;
          margin: 8px 0 0;
          padding: 16px 0;
        }
      }
    `}

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    height: ${(p) => (p.$big ? "64px" : "auto")};
    width: ${(p) => (p.$big ? "576px" : p.$short ? "360px" : "400px")};
  }
`;
