import styled from "styled-components";

export const Footer = styled.footer`
  background: ${(props) => props.theme.degradeAzul};
`;

export const Partners = styled.div`
  padding-top: 48px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 8px;
  row-gap: 42px;

  img {
    justify-self: start;
    align-self: center;
    max-height: 150px;
    max-width: 200px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Taxcel = styled.div`
  margin: 48px 0;
  padding: 24px 0;
  border-top: 1px solid ${(props) => props.theme.verde}40;
  border-bottom: 1px solid ${(props) => props.theme.verde}40;

  p {
    font-size: 21px;
    font-weight: 600;
    color: var(--color-white);
    margin: 24px 0;
  }

  div:last-child {
    margin-right: 0;
    margin-left: 0;

    a {
      margin-left: 24px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    display: flex;
    align-items: center;

    p {
      margin: 0px 0px 0px 48px;
    }

    div:last-child {
      margin-right: 0;
      margin-left: auto;

      a {
        margin-left: 24px;
      }
    }
  }
`;

export const Links = styled.div`
  color: ${(props) => props.theme.branco};
  padding-bottom: 16px;

  h4 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 24px;
    color: ${(props) => props.theme.verde};
  }

  div a {
    color: var(--color-white);
    display: block;
    margin-bottom: 12px;
    text-decoration: none;
  }

  & > div {
    margin-bottom: 40px;
  }

  .contato {
    .icone-texto {
      display: flex;
      align-items: flex-start;
      margin-bottom: 24px;

      img {
        margin-right: 8px;
      }

      a {
        margin-bottom: 4px;
      }
    }

    .atendimento {
      margin-left: 32px;
      font-size: 14px;
      opacity: 0.65;
    }
  }

  @media (min-width: ${(p) => p.theme.bp.mobile}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${(p) => p.theme.bp.desktop}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const Copyright = styled.p`
  background-color: var(--color-white);
  color: ${(props) => props.theme.textDarkGray};
  font-size: 16px;
  padding: 32px 0;
`;
