import styled from "styled-components";

export const Textarea = styled.div`
  margin-top: ${(p) => (p.$mt ? p.$mt : "0")}px;
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")}px;
  overflow: hidden;

  label {
    transition: all 150ms ease-in-out;
    display: block;
    color: ${(p) => p.theme[p.$color]};
    color: ${(p) => p.theme.textLightGray};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  textarea {
    font-family: 'Segoe UI', 'Verdana', sans-serif;
    background-color: ${(props) => props.theme.branco};
    border: 2px solid
      ${(p) => (p.error ? p.theme.dangerLight : p.theme.input.border)};
    border-radius: 0 12px;
    outline: none;
    padding: 12px 16px;
    font-size: 16px;
    color: ${(props) => props.theme.text};
    word-wrap: break-word;
    width: 100%;
    margin: 0;
    resize: none;
    height: 120px;
    transition: all 150ms ease-in-out;
    
    &:valid {
      border: 2px solid ${(p) => (p.error ? p.theme.danger : p.theme[p.$color])};
      background: var(--color-white);
    }

    &:focus {
      box-shadow: ${(p) => p.theme.input.shadow[p.$color]};
      border: 2px solid ${(p) => p.theme[p.$color]};
      background: ${(p) => p.theme.input.bg};
    }
  }
  
  &:focus-within {
    label {
      font-weight: 600;
      color: ${(p) => p.theme[p.$color]};
    }
  }
`;

export const Error = styled.p`
  color: ${(p) => p.theme.danger};
  font-size: 14px;
  margin: 4px 8px;
`;
