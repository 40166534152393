import React from "react";
import * as S from "./style";

export default function FormInput({
  className,
  name,
  label,
  negative,
  big,
  short,
  center,
  placeholder,
  mt,
  mb,
  color = "primary",
  error,
  errorMsg,
  type = "text",
  width,
  ...props
}) {
  return (
    <S.Input
      className={className}
      $big={big}
      $short={short}
      $negative={negative}
      $center={center}
      $color={color}
      $mt={mt}
      $mb={mb}
      width={width}
      error={error}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <input id={name} name={name} placeholder={placeholder} type={type} {...props} />
      {error && <S.Error>{errorMsg}</S.Error>}
    </S.Input>
  );
}
