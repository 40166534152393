import styled from "styled-components";

export const Input = styled.div`
  margin-top: ${(p) => (p.$mt ? p.$mt : "0")}px;
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")}px;
  width: ${(p) => (p.width ? `${p.width}px` : "auto")};

  label {
    transition: all 150ms ease-in-out;
    display: block;
    color: ${(p) => p.theme[p.$color]};
    color: ${(p) => p.theme.textLightGray};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  input {
    transition: all 150ms ease-in-out;
    display: block;
    width: 100%;
    font-size: 18px;
    box-sizing: border-box;
    background: var(--color-white);
    border-radius: 0 12px 0 12px;
    border: 2px solid
      ${(p) => (p.error ? p.theme.dangerLight : p.theme.input.border)};
    outline: none;
    padding: 8px 8px 8px 16px;

    &:valid {
      border: 2px solid ${(p) => (p.error ? p.theme.danger : p.theme[p.$color])};
      background: var(--color-white);
    }

    &:focus {
      box-shadow: ${(p) => p.theme.input.shadow[p.$color]};
      border: 2px solid ${(p) => p.theme[p.$color]};
      background: ${(p) => p.theme.input.bg};
    }
  }

  &:focus-within {
    label {
      font-weight: 600;
      color: ${(p) => p.theme[p.$color]};
    }
  }
`;

export const Error = styled.p`
  color: ${(p) => p.theme.danger};
  font-size: 14px;
  margin: 4px 8px;
`;
