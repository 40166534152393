import styled from "styled-components";
import { Input as InputWrapper } from "../Input/style.js";

export const Button = styled.button`
  padding: ${(props) =>
    (props.$size === "small" && "8px 12px") ||
    (props.$size === "big" && "16px 64px") || "12px 40px"};
  background: ${(props) => props.$ghost ? "transparent" : props.theme[props.$bg]};
  color: ${(props) => props.theme[props.$color]};
  border: ${(props) => props.$ghost ? `2px solid ${props.theme[props.$bg]}` : "none"};
  border-radius: 0px 12px;
  box-shadow: ${(p) => (p.$ghost ? "none" : p.$flat ? p.theme.shadow.flat : p.theme.shadow[p.$bg])};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 200ms ease-out;
  width: ${(p) => (p.$fluid ? "100%" : "auto")};
  margin-top: ${(p) => (p.$mt ? p.$mt : "0")}px;
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")}px;
  margin-left: ${(p) => (p.$center ? "auto" : "0")};
  margin-right: ${(p) => (p.$center ? "auto" : "0")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    color: ${(p) => (p.$iconColor ? p.theme[p.$iconColor] : "inherit")};
    margin: ${(p) => (p.$iconLeft ? "0 12px 0 0" : "0 0 0 12px")};
  }

  &:hover {
    background-color: ${(props) => props.$ghost ? props.theme[props.$color] : props.theme.hover[props.$bg]};
    color: ${(props) => props.$ghost ? props.theme.branco : props.theme[props.$color]};
  }

  /* Button on Input */
  ${InputWrapper} & {
    align-self: stretch;
    padding: 9px 24px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: auto;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    width: ${(p) => (p.$fullWidth ? "100%" : "auto")};
  }
`;
