import { useState } from "react";

export const validarCpf = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf === "") return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
};

export const validarCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(1)) return false;

  return true;
};

export const validarCnpjCpf = (value) => {
  value = value.replace(/[^\d]+/g, "");

  if (value.length < 11) {
    return true;
  }

  if (value.length < 14) {
    return !validarCpf(value);
  }

  return !validarCNPJ(value);
};

export const useInput = (
  initialValue,
  maskType = null,
  errorType = maskType
) => {
  const [value, setValue] = useState(initialValue);
  const [isInvalid, setIsInvalid] = useState(false);

  const error = {
    email: function (value) {
      return !value.match(/\S+@\S+\.\S+/);
    },
    tel: function (value) {
      return value.length < 14;
    },
    cnpj: function (value) {
      return validarCnpjCpf(value);
    },
    cep: function (value) {
      return value.length < 9;
    },
    estado: function (value) {
      return value.length !== 2;
    },
    vazio: function (value) {
      return value.length < 1;
    },
    alwaysValid: function () {
      return false;
    },
  };

  const mask = {
    tel: function (value) {
      let v = value.replace(/\D/g, "");
      v = v.replace(/^(\d\d)(\d)/g, "($1) $2");

      if (value.length < 15) v = v.replace(/(\d{4})(\d)/, "$1-$2");
      else v = v.replace(/(\d{5})(\d)/, "$1-$2");

      v = v.substring(0, 15);

      return v;
    },
    cnpj: function (value) {
      let v = value.replace(/\D/g, "");
      if (value.length < 16) {
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        // v = v.replace(/(\d{2})(\d)/, "$1-$2");
        v = v.substring(0, 18);
        return v;
      }

      v = v.replace(/(\d{2})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
      v = v.substring(0, 18);

      return v;
    },
    cep: function (value) {
      let v = value.replace(/\D/g, "");
      v = v.replace(/(\d{5})(\d)/, "$1-$2");
      v = v.substring(0, 9);

      return v;
    },
    estado: function (value) {
      let v = value.replace(/[^a-zA-Z]/g, "");
      // v = v.replace(/(\d{2})(\d)/, "$1-$2");
      v = v.substring(0, 2);
      v = v.toUpperCase();

      return v;
    },
  };

  return {
    value,
    setValue,
    isInvalid,
    setIsInvalid,
    reset: () => {
      setValue("");
      setIsInvalid(false);
    },
    bind: {
      value,
      onChange: (event) => {
        setValue(
          maskType in mask
            ? mask[maskType](event.target.value)
            : event.target.value
        );
        if (isInvalid) {
          setIsInvalid(
            errorType in error
              ? error[errorType](event.target.value)
              : event.target.value.length < 1
          );
        }
      },
      onBlur: (event) => {
        setIsInvalid(
          errorType in error
            ? error[errorType](event.target.value)
            : event.target.value.length < 1
        );
      },
    },
  };
};
