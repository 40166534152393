import React from 'react'
import * as S from "./style"

export default function Button({className, onClick, bg = "primary", color = "branco", iconLeft, iconRight, iconColor, mt, mb, size = "default", flat, fluid, fullWidth, children, ghost, center}) {
    return (
        <S.Button className={className} onClick={onClick} $bg={bg} $color={color} $iconColor={iconColor} $iconLeft={iconLeft} $iconRight={iconRight} $icon={iconLeft || iconRight} $fluid={fluid} $fullWidth={fullWidth} $center={center} $flat={flat} $size={size} $ghost={ghost} $mt={mt} $mb={mb}>
            {children}
        </S.Button>
    )
}
