import React, { useEffect, useState } from "react";
import Button from "../../DesignSystem/Button";
import FormInput from "../../DesignSystem/FormInput";
import Modal from "../../DesignSystem/Modal";

import * as S from "./style";

import { useInput } from "../../hooks/useInput";
import Textarea from "../../DesignSystem/Textarea";

export default function ModalContato(props) {
  const [isVisible, setIsVisible] = useState(false);

  const {
    value: email,
    setValue: setEmail,
    isInvalid: isInvalidEmail,
    bind: bindEmail,
    reset: resetEmail,
  } = useInput("", "email");
  const {
    value: nome,
    isInvalid: isInvalidNome,
    bind: bindNome,
    reset: resetNome,
  } = useInput("");
  const {
    value: tel,
    isInvalid: isInvalidTel,
    bind: bindTel,
    reset: resetTel,
  } = useInput("", "tel");
  const {
    value: msg,
    isInvalid: isInvalidMsg,
    bind: bindMsg,
    reset: resetMsg,
  } = useInput("");

  const sendForm = () => {
    if (
      email === "" ||
      isInvalidEmail ||
      nome === "" ||
      isInvalidNome ||
      tel === "" ||
      isInvalidTel ||
      msg === "" ||
      isInvalidMsg
    ) {
      alert("Preencha todos os campos para enviar a mensagem");
      return;
    }
    console.log(nome, email, tel, msg);
    resetEmail();
    resetNome();
    resetTel();
    resetMsg();
  };

  useEffect(() => {
    setEmail(props.email);
  }, [isVisible, props.email, setEmail]);

  const handleDisplayChange = (display) => {
    setIsVisible(display);
  };

  return (
    <Modal onDisplayChange={handleDisplayChange} ref={props.refModal}>
      <S.Title>Deixe sua mensagem que entraremos em contato</S.Title>
      <FormInput
        required
        name="nome"
        label="Nome"
        mt={16}
        placeholder="Nome Sobrenome"
        error={isInvalidNome}
        errorMsg="Campo obrigatório"
        {...bindNome}
      />
      <FormInput
        required
        name="email"
        label="Email"
        type="email"
        mt={16}
        placeholder="nome@email.com"
        error={isInvalidEmail}
        errorMsg="Digite um email válido para continuar"
        {...bindEmail}
      />
      <FormInput
        required
        name="tel"
        type="tel"
        label="Telefone"
        mt={16}
        placeholder=""
        error={isInvalidTel}
        errorMsg="Digite um telefone válido para continuar"
        {...bindTel}
      />
      <Textarea
        required
        name="mensagem"
        label="Mensagem"
        mt={16}
        error={isInvalidMsg}
        errorMsg="Campo obrigatório"
        {...bindMsg}
      />
      <Button fluid fullWidth center mt={32} onClick={sendForm}>
        Enviar Mensagem
      </Button>
    </Modal>
  );
}
