import React from 'react'
import * as S from "./style"

export default function Input({className, negative, children, big, short, center, placeholder, mt, mb, color = "primary", ...props}) {
    return (
        <S.Input className={className} $big={big} $short={short} $negative={negative} $center={center} $color={color} $mt={mt} $mb={mb} {...props}>
            <input placeholder={placeholder} type="text" {...props}/>
            {children}
        </S.Input>
    )
}
