import React from "react";
import * as S from "./style";

export default function Textarea({
  mb,
  mt,
  label,
  name,
  color = "primary",
  error,
  errorMsg,
  ...props
}) {
  return (
    <S.Textarea error={error} $mt={mt} $mb={mb} $color={color}>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea id={name} name={name} {...props} />
      {error && <S.Error>{errorMsg}</S.Error>}
    </S.Textarea>
  );
}
