import styled from "styled-components";

export const Container = styled.div`
  max-width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  overflow: ${p => p.$clip ? "hidden" : "visible"};

  @media (min-width: ${p => p.theme.bp.mobile}) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (min-width: ${p => p.theme.bp.desktop}) {
    overflow: visible;
  }

  @media (min-width: ${p => p.theme.bp.bigDesktop}) {
    padding-left: 0;
    padding-right: 0;
    max-width: 1172px;
    margin-left: auto;
    margin-right: auto;
  }
`;
