import { motion } from "framer-motion";
import styled from "styled-components";

export const Cookies = styled(motion.div)`
  z-index: 1111;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(253, 110, 75, 0) 0%,
    rgba(253, 110, 75, 0.61) 155.75%
  );

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    height: 100px;
  }
`;

export const Popup = styled(motion.div)`
  background: #ffffff;
  box-shadow: 0px 12px 24px -6px rgba(96, 96, 96, 0.15),
    0px 0px 1px rgba(96, 96, 96, 0.1);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  padding: 16px;

  p {
    margin: 0 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .buttons {
    display: flex;

    button:first-child {
      margin-right: 16px;
    }
  }

  @media (max-width: ${(p) => p.theme.bp.tablet}) {
    img {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 40px;
    }

    p {
      display: inline-block;
      width: 200px;
    }

    .buttons {
        margin-top: 16px;
        justify-content: center;
    }
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    display: flex;
    width: 624px;
    height: 72px;
  }
`;
