import styled from "styled-components";

export const Modal = styled.div`
  z-index: 1111;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  
  .modal-wrapper {
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 1110;
    overflow: auto;
  }
  
  .background {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 101;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  
  .modal {
    box-sizing: border-box;
    z-index: 1101;
    background: ${(props) => props.theme.branco};
    height: auto;
    width: 90%;
    margin: 64px auto;
    position: relative;
    padding: 32px 32px;
    border-radius: 0 32px;
    
    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-width: 480px;
      margin: 128px auto;
      min-height: 300px;
    }

    .close {
      position: absolute;
      display: block;
      top: 16px;
      right: 16px;
      border: none;
      background: none;
      cursor: pointer;
      height: 24px;
      width: 24px;
      box-sizing: border-box;
      padding: 0;
      opacity: .5;
      transition: all 200ms ease-in-out;
      
      &:hover {
        opacity: 1;
      }

      svg {
        height: 24px;
        width: 24px;
        fill: ${({ theme }) => theme.text};
      }
    }
  }
`;
